$background-color: #eeeff0;
$light-text-color: #94999d;
$light-border-color: #dadada;
$dark-text-color: #242424;
$blue-text-color: #041e42;
$white-color: white;
$light-blue-color: #f2f7f9;
$primary-color: #033a6a;
$success-color: #44a878;
$warning-color: #f6a98e;
$danger-color: #cc1040;
$info-color: #4a8ba4;
$user-icon: #0F3263;