@import "../style/sizes";
@import "../style/colors";
@import "../style/fonts";
$dropdown-background: #fff;

:global(.dropdown-menu) {

    &.menu {
        display: block;
        background-color: $dropdown-background;
        top: 0;
        margin: 0;
        padding-top: 0;
        border: none;
        border-radius: 0;
        padding-bottom: 0;

        @media all and (max-width: $tablet-width) {
            width: 100%;
            position: fixed;
            z-index: 2001;
            top: 0;
            height: 100%;
            border: none;
            overflow-x: hidden;
            overflow-y: scroll;
        }

        hr {
            border-color: $light-border-color;
        }

        .container {
            width: auto;
            margin-top: 10px;
            margin-bottom: 14px;

            @media all and (max-width: $mobile-width) {
                margin: 10px 14px;
            }

            h5 {
                font-size: 16px;
                padding-top: 0;
            }

            h6 {
                color: $light-text-color;
                font-family: $scania-sans-semi-cond-bold;
                font-style: normal;
                font-weight: 400;
                margin-top: 4px;
                margin-bottom: 4px;
            }

            :global(.row) {

                &:first-child {

                    :global(.col-xs-10) {
                        white-space: nowrap;

                        h5 {
                            min-width: 600px;
                            line-height: 28px;
                        }
                    }
                }

            }



            :global(.close) {
                span {
                    margin-right: 0;

                    img {
                        width: 28px;
                    }
                }
            }
        }
    }
}