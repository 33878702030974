@import "../../style/colors";
@import "../../style/sizes";

.subheader {
    font-size: 5rem;
  }
  
  .individualDetail {
    padding-right: 30px;
    padding-top: 15px;
    font-size: 3.6rem;
  }
  
  .specDetailsContainer {
    display: flex;
    flex-wrap: wrap;
  }
  
  .specModalIcon {
    margin-top: -0.225em;
  }

  .popupSpinnerContainer {
    width: 80px
  }

  .popupIcon {
    cursor: pointer;
    color: #4A8BA4;
    margin-left: 5px;
  }