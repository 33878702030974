@import "../style/fonts";
@import "../style/colors";
@import "../style/sizes";


:global(.propulsion-type-buttons) li {
    @media (max-width: $mobile-width) {
        display: inline !important;
        width: unset !important;
        float: left !important;
        margin-top: 10px;
    }
}

:global(.propulsion-type-buttons) {
    margin-top: 10px !important;
    button{
        border: 0px;
        background: none;
        outline-width: 0px;
        font-size: 1em;
        
    }

       

    li{
        border-color: $primary-color !important;
        margin-right: 10px;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        min-width: 85px;
    }
    :global(.active){
        background-color: $primary-color !important;
        border-color: $primary-color !important;
        :global(button){
            color: white !important;
        }
    }
}