@import '../style/corporate-ui';
@import "../style/fonts";
@import "../style/colors";
@import "../style/sizes";
@import "../style/sdds-corporate-ui-compatibility-hacks";

$scroll-color: #ebebeb;

:global(body) {
  background-color: #eeeff0 !important;
}

:global {
  div::-webkit-scrollbar-track {
    width: 4px;
    background-color: transparent;
  }

  div::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  div::-webkit-scrollbar-thumb {
    height: 78px;
    background-color: $scroll-color;
  }

}

:global(.clickable) {
  cursor: pointer;
}

:global(.app) {
  :global(.input-lg) {
    &:global(.form-control) {
      height: 39px;
    }
  }

  :global(.login-header) {
    font-size: 5rem;
  }

  :global(.form-control) {
    height: 43px;
    font-size: 3.5rem;

    option {
      font-size: 3.5rem;
    }
  }

  :global(select) {
    &:global(.form-control) {
      font-size: 3.5rem;
    }
  }

  :global(label) {
    font-size: 3.2rem;
  }

  :global(nav) {
    a {
      text-transform: inherit;
    }
    a:focus, a:hover {
      text-decoration: none;
    }
  }

  :global(.table) {
    thead {
      tr {
        th {
          font-size: 3.5rem;
        }
      }
    }
  }
}

:global(small) {
  :global(b) {
    font-family: "Scania Sans Semi Condensed Bold";
    color: #242424;
  }
}

p {
  color: #242424;
  font-size: 3.4rem !important;
}

:global(.popover) {
  &:global(.scania-popover) {
    z-index: 2002;
    border-radius: 0;
    border: 1px solid #dadada;
  }
}

:global(.btn) {
  &:global(.disabled) {
    &:global(.restricted) {
      cursor: pointer;
      pointer-events: fill;
    }
  }
}

:global(.app) {
  :global(.container-fluid) {
    @media all and (max-width: $mobile-width) {
      padding-left: 0;
      padding-right: 0;
      overflow-x: hidden !important;
    }

  }

    section {
      &:global(.dark) {
        margin-top: 20px;
        background-color: #eeeff0 !important;
        overflow: visible;
        min-height: calc(100vh - 240px);
        position: relative;
      }
    }
}

button {
  &:global(.close) {
    opacity: 1;
  }
}

:global(.app) {
  :global(.jumbotron) {
    padding-top: 24px;
    padding-bottom: 24px;

    @media all and (max-width: $mobile-width) {
      padding: 0;
    }

    &:global(.white) {
      background-color: white;
    }

    margin-bottom: 0px;

    h2 {
      font-family: $scania-sans-headline;
    }

    h1 {
      font-size: 25px;
      padding: 0;
      margin-bottom: 12px;
      margin-top: 7px;
    }

    p {
      font-family: $scania-sans-semi-cond;
      font-weight: 400;
      color: $blue-text-color
    }
  }
}

@media all and (max-width: $mobile-width) {
  :global(.collapsable-xs) {
    display: none;
  }
}

@media all and (max-width: $tablet-width) {
  :global(.collapsable-sm) {
    display: none;
  }
}

@media all and (max-width: $small-desktop-width) {
  :global(.collapsable-md) {
    display: none;
  }
}

:global(.slideUp) {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  visibility: visible !important;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }

  10% {
    transform: translateY(90%);
  }

  20% {
    transform: translateY(80%);
  }

  30% {
    transform: translateY(70%);
  }

  40% {
    transform: translateY(60%);
  }

  50% {
    transform: translateY(50%);
  }

  60% {
    transform: translateY(40%);
  }

  70% {
    transform: translateY(30%);
  }

  80% {
    transform: translateY(20%);
  }

  90% {
    transform: translateY(10%);
  }

  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
  }

  0% {
    -webkit-transform: translateY(100%);
  }

  10% {
    -webkit-transform: translateY(90%);
  }

  20% {
    -webkit-transform: translateY(80%);
  }

  30% {
    -webkit-transform: translateY(70%);
  }

  40% {
    -webkit-transform: translateY(60%);
  }

  50% {
    -webkit-transform: translateY(50%);
  }

  60% {
    -webkit-transform: translateY(40%);
  }

  70% {
    -webkit-transform: translateY(30%);
  }

  80% {
    -webkit-transform: translateY(20%);
  }

  90% {
    -webkit-transform: translateY(10%);
  }

  100% {
    -webkit-transform: translateY(0%);
  }
}

:global(.hidden) {
  display: none;
}