@import "../style/colors";
@import "../style/sizes";
@import "../style/fonts";
@import "../style/table";

@media all and (max-width: $tablet-width) {
    .responsiveTable {
        overflow-x: inherit;
    }
}

table:global(.table) {
    &.lightTable {

        margin-bottom: 15px;

        thead {
            border-bottom: 2px solid $background-color;
            background-color: $white-color;
            color: $light-text-color;

            &:global(.sticky-header) {
                z-index: 2;
            }

            tr {
                th {
                    border: none !important;
                    white-space: nowrap;
                    text-align: right;
                    padding-right: $table-padding;
                    padding-top: $table-outer-padding;
                    padding-bottom: calc(#{$table-outer-padding} - 5px);

                    div {
                        float: right;
                        clear: both;
                    }

                    @media all and (max-width: $small-desktop-width) {
                        div {
                            max-width: 80px;
                        }

                        padding-right: 5px;
                        padding-top: $mobile-table-padding;
                        padding-bottom: $mobile-table-padding;
                        padding-left: 5px;
                    }

                    button:focus {
                        outline: 0;
                    }
                }

                th:last-child {
                    padding-right: $table-outer-padding;
                    padding-left: $table-padding;

                    @media all and (max-width: $tablet-width) {
                        padding-right: $mobile-table-padding;
                        padding-left: 5px;
                    }
                }

                th:first-child {
                    text-align: left;
                    padding-left: $table-outer-padding;
                    padding-right: $table-padding;

                    @media all and (max-width: $tablet-width) {
                        padding-left: $mobile-table-padding;
                        padding-right: 5px;
                    }

                    div {
                        float: left;
                    }
                }
            }
        }

        tbody {
            tr:nth-child(odd) {
                td {
                    background-color: $light-blue-color;
                }
            }

            tr:nth-child(even) {
                td {
                    background-color: $white-color;
                }
            }

            tr {
                td {
                    border: none;
                    padding: $table-padding;
                    text-align: right;
                    font-family: $scania-sans-cond;
                    font-size: 14px;
                    color: $dark-text-color;
                    vertical-align: middle;

                    @media all and (max-width: $tablet-width) {
                        padding: $mobile-table-padding;
                    }
                    @media all and (max-width: $small-desktop-width) {
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                }

                td:first-child {
                    color: $dark-text-color;
                    font-family: $scania-sans-cond-bold;
                    text-align: left;
                    padding-left: $table-outer-padding;

                    @media all and (max-width: $tablet-width) {
                        padding-left: $mobile-table-padding;
                    }
                }

                td:last-child {
                    padding-right: $table-outer-padding;

                    @media all and (max-width: $tablet-width) {
                        padding-right: $mobile-table-padding;
                    }
                }

                &:global(.disabled) {
                    td {
                        color: $light-text-color;
                    }
                }
            }
        }
    }
}