@import "../style/fonts";
@import "../style/colors";
$modal-header-color: #f6f9fa;
.launch-modal {
    &:global(.modal-dialog) {
        width: 100% !important;
        :global(.modal-header) {
            background-color: $modal-header-color;
            border-bottom: 0px;
            text-align: right;
            :global(.close) {
                opacity: 1;
                span {
                    color: black;
                }
            }
        }
        :global(.modal-footer) {
            border-top: 0px;
            margin-bottom: 8px;
            text-align: center;
        }
        :global(.modal-body) {
            padding-top: 0px;
        }
    }
}