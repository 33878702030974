@import "../style/fonts";
@import "../style/colors";

.parameter-info-modal {
    &:global(.modal-dialog) {
        width: 100% !important;

        :global(.modal-body)
        {
            padding: 0px;
        }
        :global(.modal-header) {
            border-bottom: 0px;

            span {
                font-size: 32px;
            }
        }

        .title {
            color: $dark-text-color;
            font-size: 20px;
            padding-left: 60px;
            font-family: $scania-sans-semi-cond-bold;
        }
        :global(.with_padding){
            padding-left: 60px;
            padding-right: 60px;
            padding-top: 20px;
            padding-bottom: 20px;
        }
        .help_menu_bar{
            -webkit-box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.2);
            padding-left: 30px;
            :global(.active-help-menu){
                border-bottom: 2px solid #CC1040;

            }
            span{
                color: black;
                margin-left: 30px;
                display: inline-block;
                padding-bottom: 5px;
                cursor: pointer;
                font-size: 12px;
            }
        }
        .parameter-name {
            color: $dark-text-color;
            font-size: 14px;
            font-family: $scania-sans-semi-cond-bold;
            margin-bottom: 5px;
        }
        .parameter-description_less_padding{
            color: $dark-text-color;
            font-size: 14px;
            font-family: $scania-sans-semi-cond;
            margin-bottom: 0px;
            img{
                margin-right: 5px;
            }
        }

        .parameter-description {
            color: $dark-text-color;
            font-size: 14px;
            font-family: $scania-sans-semi-cond
        }
    }
}