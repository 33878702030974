@import "../../style/fonts";
@import "../../style/sizes";
@import "../../style/colors";

@media all and (max-width: $tablet-width) {

    .vehicleName {
        white-space: nowrap;
        max-width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;

        &:global(.btn) {
            &:global(.btn-link) {
                color: $info-color;
                padding: 0;
                border: 0;
            }
        }
    }

}

.iconsContainer {
    width: auto;
    display: flex;
    margin-left: 1rem;
}