@import "../../style/colors";
@import "../../style/sizes";
$toggle-indicator-color: $light-text-color;

.popoverContainer {  
  .carousel-top-bar {
    background-color: white;
    display: block;
    width: 100%;
    height: 30px;
  }
  :global(.carousel-control) {
      &:global(.left) {
          background-image: none;
          background-repeat: no-repeat;
          span {
              text-shadow: none;
              @media (max-width: $mobile-width) {
                  top: 75%;
              }
              color: $toggle-indicator-color;
          }
      }
      &:global(.right) {
          background-image: none;
          background-repeat: no-repeat;
          span {
              text-shadow: none;
              @media (max-width: $mobile-width) {
                  top: 75%;
              }
              color: $toggle-indicator-color;
          }
      }
  }
  :global(.carousel-indicators) {
    bottom: 0;
      li {
          background-color: #e2e2e2;
          &:global(.active) {
              background-color: $light-text-color;
          }
      }
  }
  .carousel-item {
      padding-top: 10px;
      padding-bottom: 40px;
      background-color: white;
      padding-left: 40px;
      padding-right: 40px;
  }
}

.subheader {
  font-size: 3.6rem;
}

.individualDetail {
  padding-right: 30px;
  padding-top: 15px;
  font-size: 3.6rem;
}

.specDetailsContainer {
  display: flex;
  flex-wrap: wrap;
}

.specModalIcon {
  margin-top: -0.225em;
}

.changeButton {
  margin-top: 20px;

  :global(.btn) {
    width: 100%;
  }
}