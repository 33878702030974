@import "../style/colors";
.login-form {
    :global(.showhidepassword){
        float: right;
        font-family: 'Scania Sans Semi Condensed';
        font-weight: bold;
        font-size: 1.2rem;
        color: #4a8ba4;
        text-decoration: none;
    }
    :global(a) {
        &:hover {
            color: $info-color;
        }
        :global(b) {
            color: $info-color;
        }
    }
}