@import "../../style/sizes";
@import "../../style/colors";
@import "../../style/fonts";
$badge-color: #94a596;
$picker-background: white;

.dropdown {
  :global(.dropdown-menu) {
    background-color: $picker-background;
  }

  .picker-dropdown {
    border: 1px solid $primary-color;
    background-color: $picker-background;
    color: $dark-text-color;
    text-align: left;

    :global(.badge) {
      float: right;
      margin-left: 12px;
    }
    img {
      width: 16px;
      display: inline-block;
    }

    &:hover {
      border: 1px solid $primary-color;
      background-color: #eee;
    }

    &:global(.disabled) {
      border: 1px solid $primary-color;
    }

    img {
      &.picker-dropdown-button-icon {
        float: right;
        margin-left: 12px;
        margin-top: -4px;
        margin-bottom: -10px;
      }
    }
    span {
      display: inline-block;
      cursor: pointer;
      vertical-align: middle;
      &:global(.badge) {
        background-color: $primary-color;
        padding: 7px 13px;
        margin-right: 0;
        top: 0;
      }
    }
  }
}

.report-picker-header-label {
  h5 {
    display: inline-block;
    padding: 0;
    margin: 0;
    font-size: 16px;

    small {
      font-family: $scania-sans-semi-cond;
      font-size: 12px;
      margin-right: 12px;
    }
  }
}

:global(.btn) {
  &:global(.btn-lg) {
    &.picker-dropdown {
      white-space: normal;
      padding: 0;
      padding: 8px;
      padding-left: 12px;
      padding-right: 12px;
      border-color: rgb(218, 218, 218);
      min-width: 250px;
      @media all and (max-width: $mobile-width) {
        display: block;
        width: 100%;
      }
    }
  }
}

.report-picker-dropdown-content {
  img {
    width: 18px;
  }

  @media all and (min-width: $tablet-width) {
    min-width: 656px;
  }

  background-color: $picker-background;
  top: 0;
  margin: 0;
  padding-top: 0;
  border: 1px solid $picker-background;
  border-radius: 0;
}

.report-picker-dropdown-content {
  :global(.btn-group) {
    :global(.btn) {
      font-size: 13px;
    }
  }

  .report-picker-time-period-column {
    padding: 5px;

    &:first-child {
      padding-left: 0px;
    }

    &:last-child {
      padding-right: 0px;
    }
  }

  :global(.col-xs-12) {
    padding: 0;
  }

  :global(.col-xs-10) {
    padding: 0;
  }

  :global(.col-xs-2) {
    padding: 0;
  }

  :global(.list-group) {
    margin-bottom: 0;
  }

  .report-picker-row {
    border-bottom: 1px solid $light-border-color;
    padding-bottom: 10px;
    padding-top: 10px;

    .report-picker-header-column {
      padding: 0;

      h6 {
        &:global(.pull-right) {
          text-align: right;
          font-size: 3.4rem;
        }
        &:global() {
          font-size: 3.4rem;
        }
      }

      small {
        display: inline-block;
      }
    }

    &:first-child {
      padding-top: 0;

      :global(.col-xs-10) {
        line-height: 28px;
      }
    }

    &:last-child {
      padding-top: 0;
      border-bottom: 0;
      padding-bottom: 0;
    }

    &:nth-last-child(2) {
      padding-top: 0;
      padding-bottom: 15px;
      border-bottom: 0;
    }

    &:nth-last-child(3) {
      padding-bottom: 0;
      border-bottom: 0;
    }

    h6 {
      padding: 0;
      margin: 0;
      padding-bottom: 10px;
      color: $light-text-color;
      font-family: $scania-sans-semi-cond-bold;
      font-style: normal;
      font-weight: 400;
    }

    .report-picker-header-icon {
      margin-right: 10px;
    }

    :global(.close) {
      span {
        margin-right: 0;

        img {
          width: 28px;
        }
      }
    }
  }

  :global(.badge) {
    background-color: $badge-color;
    color: $white-color;
    font-family: $scania-sans-semi-cond;
    font-weight: normal;
  }

  :global(.list-group-item) {
    padding: 8px;

    @media all and (max-width: $tablet-width) {
    }

    color: black;
    border: 1px solid $light-border-color;
    font-family: $scania-sans-cond-bold;
    margin: 0;

    &:global(.active) {
      color: $white-color;

      :global(.badge) {
        background-color: $white-color;
        color: $badge-color;
      }
    }

    &:nth-child(even) {
      border-top: none;
    }

    &:nth-child(odd) {
      border-top: none;
    }

    &:first-child {
      border-top: 1px solid $light-border-color;
    }

    @media all and (max-width: $tablet-width) {
      padding: 10px;
      margin: 5px;

      &:nth-child(even) {
        border-top: 1px solid $light-border-color;
      }

      &:nth-child(odd) {
        border-top: 1px solid $light-border-color;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 30px;
      }
    }

    @media all and (max-width: $mobile-width) {
      padding-right: 5px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .report-picker-submit-row {
    :global(.col-xs-12) {
      @media all and (max-width: $tablet-width) {
        position: fixed;
        bottom: 0;
        left: 0;
      }
    }

    :global(.btn-group) {
      float: right;

      :global(.btn) {
        &:global(.disabled) {
          margin-right: 5px;
          color: $dark-text-color;
          opacity: 1;
        }
      }
    }

    a:hover {
      text-decoration: none;
    }
  }
}

.togglePeriodPickerBtn {
  margin-top: 15px;
}

.dateInput {
  width: 180px;
  margin-right: 20px;
}

.dateError {
  width: 180px;
  color: red;
}

.timeError {
  width: 135px;
  color: red;
}

.timeInput {
  width: 135px;
}

.dateTimeContainer {
  display: flex;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  align-items: baseline;
  flex-wrap: wrap;

  h5 {
      width: 170px;
      font-size: 14px;
  }
}
