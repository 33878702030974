.alertBox {
    margin: 0px;
    &:global(.alert) {
        margin-bottom: 0;

        p {
            font-size: 3.4rem;
        }

        a {
            font-size: 3.4rem;
        }
    }
}