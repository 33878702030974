@import "../style/fonts";
@import "../style/colors";
@import "../style/sizes";

.actionbar {
    text-align: right;

    @media all and (max-width: $tablet-width) {
        text-align: center;
    }
}