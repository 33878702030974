@import "../style/colors";
$spiner-thickness: 5px;
$spinner-size: 60px;
$spinner-background: transparent;
$main-load-page-height: calc(100vh - 163px);
$popup-spinner-size: 30px;
.spinner {
    border: $spiner-thickness solid $spinner-background;
    border-top: $spiner-thickness solid $primary-color;
    border-radius: 50%;
    width: $spinner-size;
    height: $spinner-size;
    animation: spin 1s linear infinite;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0
}

.container {
    display: inline-block;
    margin: auto;
    width: 100%;
    height: $spinner-size;

    &:global(.mains) {
        min-height: $main-load-page-height;
        height: $main-load-page-height;
    }

    &:global(.mediumSpinner) {
        min-height: $popup-spinner-size;
        height: $popup-spinner-size;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}