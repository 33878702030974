@import "../../style/fonts";
@import "../../style/sizes";

.headerName {
    font-family: $scania-sans-semi-cond-bold;
    font-weight: 400;
    font-size: 12px;
    text-transform: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
}

.headerUnit {
    font-family: $scania-sans-semi-cond;
    font-weight: 400;
    font-size: 12px;
    text-transform: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/*
@media all and (max-width: $small-desktop-width) {
    .headerName {
        max-width: 110px;
    }
    .headerUnit {
        max-width: 110px;
    }
}

@media all and (max-width: $mobile-width) {
    .headerName {
        max-width: 70px;
    }
    .headerUnit {
        max-width: 70px;
    }
}*/