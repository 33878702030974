:global(.app .btn) {
    font-size: 14px;
}

:global(header.c-page-header .page-header h3) {
    font-size: 4rem;
}

:global(small){
    font-size: 3rem;
}

:global(body.app){
    font-size: 3.5rem !important;
}

:global(.label-sm) {
    font-size: 2.5rem;
}

:global(.glyphicon) {
    font-size: 3.5rem;
}
