@import "../style/colors";
@import "../style/fonts";
@import "../style/sizes";

:global(.row) {
  &.KPI-row {
    padding: 0px 25px 0px 25px;

    :global(.col-lg-6):first-child {
      &:first-child {
        padding-right: 0px;
      }

      &:last-child {
        padding-right: 0px;
      }
    }
  }

  @media (max-width: $tablet-width) {
    .KPI-area {
      min-height: 180px;
    }
  }
  .KPI-area {
    padding-bottom: 25px;
    display: table;
    table-layout: fixed;
    width: 100%;

    .KPI-icon {
      display: table-cell;
      width: 147px;
      text-align: center;
      @media (max-width: $tablet-width) {
        // float: right;
      }

      img {
        height: 90px;
      }
    }

    .KPI-text {
      display: table-cell;
      vertical-align: top;
    }
  }
}

.KPI-header {
  font-family: $scania-sans-semi-cond-bold;
  color: $light-text-color;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 0;
  padding-left: 5px;
}

.KPI-sub-header {
  font-family: $scania-sans-semi-cond;
  font-size: 12px;
  color: $dark-text-color;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 5px;
}

.KPI-value {
  font-family: $scania-sans-semi-cond-bold;
  font-size: 24px;
  color: $dark-text-color;
  margin-bottom: 0;
  padding-left: 5px;
}

.KPI-secondary-value {
  font-family: $scania-sans-semi-cond;
  font-size: 24px;
  color: $dark-text-color;
  margin-bottom: 0;
  padding-left: 5px;
}