@import "../../style/colors";
.wrench{
  margin-left: 10px;
}
.title{
  margin-bottom: 20px;
}

.column {
  display: inline-table;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  &:hover {
    border-bottom: 1px dotted $light-text-color;
  }
}
