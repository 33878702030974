@import "../../style/colors";
@import "../../style/sizes";
@import "../../style/fonts";
@import "../../style/table";

@media all and (max-width: $tablet-width) {
  .table-responsive {
    overflow-x: hidden;
  }
}

table:global(.table) {
  &.compact-table {

    tbody {
      tr:nth-child(odd) {
        td {
          background-color: $light-blue-color;
        }
      }

      tr:nth-child(even) {
        td {
          background-color: $white-color;
        }
      }

      tr {
        td {
          border: none;
          padding: $table-padding;
          text-align: right;
          font-family: $scania-sans-cond;
          font-size: 14px;
          color: $dark-text-color;
          vertical-align: middle;

          @media all and (max-width: $tablet-width) {
            padding: $mobile-table-padding;
          }
        }

        td:first-child {
          color: $dark-text-color;
          font-family: $scania-sans-cond-bold;
          text-align: left;
          padding-left: $table-outer-padding;

          @media all and (max-width: $tablet-width) {
            padding-left: $mobile-table-padding;
          }
        }

        td:last-child {
          padding-right: $table-outer-padding;

          @media all and (max-width: $tablet-width) {
            padding-right: $mobile-table-padding;
          }
        }

        &:global(.disabled) {
          td {
            color: $light-text-color;
          }
        }
      }
    }
  }
}

.bannerHeader {
  font-weight: 700;
}

.linkedBanner {
  cursor: pointer;
  border-radius: 8px;
  position: relative;
}

.bannerContainer {
  width: 100%;
}

.bannerSubcontainer {
  width: 100%;
  height: 100%;
}

.linkedBanner::before {
  content: " ";
  display: block;
  position: absolute;
  width: 4px;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: var(--sdds-information);
  border-radius: 8px 0 0 8px;
}

.bannerSubheader {
  font-size: 3.5rem !important;
  line-height: 4rem !important;
  letter-spacing: -0.01em;
}

.truckIcon {
  width: 30px;
  margin-left: 4px;
  margin-top: -2px;
}

.infoIcon {
  height: 20px;
  filter: invert(25%) sepia(90%) saturate(1247%) hue-rotate(195deg) brightness(92%) contrast(89%);
}