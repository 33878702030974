@import "../../style/colors";
@import "../../style/fonts";

.modal {
    h3 {
        margin-bottom: 20px;
    }

    &:global(.modal-dialog) {
        margin: 25% auto;
    }

    :global(.btn-link) {
        color: $light-text-color;
    }
}