@import "../../style/colors";
@import "../../style/fonts";
@import "../../style/sizes";


.headerName {
  font-family: $scania-sans-semi-cond-bold;
  font-weight: 400;
  font-size: 12px;
  text-transform: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  color: #242424;
}

.headerUnit {
  font-family: $scania-sans-semi-cond;
  font-weight: 400;
  font-size: 12px;
  text-transform: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #242424;
}

.linkToSP{
    margin-top: 20px;
    margin-bottom: 10px;
    display: block;
    color: #4a8ba4;
    font-weight: bold;
    text-decoration: none;
    font-family: $scania-sans-semi-cond;
}

.header{
  td{
    font-weight: bold;
    color: rgb(148, 153, 157);
    font-size: 12px;
    border-bottom: 1px solid rgb(192, 192, 192);    
  }
}

.spTable{
  min-width: 400px;
  font-size: 3.2rem;

  tbody{
    td{
      padding: 5px;
      span{
        width: 100%;  
      }
    }
  }
}

:global(.app) {
  h4 {
    font-size: 4rem;
  }

  td {
    font-size: 3.2rem;
  }
}
