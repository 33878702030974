.YNTpopoverContainer {
  background-color: #37414F;
  color: white;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  width: 220px;
  border-radius: 10px !important;

  a {
    color: white;
    text-decoration: underline;
    margin-left: 4px;
  }
}

:global(.popover) {
  &:global(.right) {
    :global(.arrow) {
      display: none !important;
    }
  }
}

.truckIcon {
  width: 25px;
  filter: invert(25%) sepia(90%) saturate(1247%) hue-rotate(195deg) brightness(92%) contrast(89%);
  cursor: pointer;
  margin-top: -5px;
}