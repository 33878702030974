@import "../style/sizes";
@import "../style/colors";
@import "../style/fonts";
$dropdown-background: #fff;



:global(.modalBox) {

    
    background-color:rgba(36, 36, 36, 0.5);
      

    display: block;

    :global(.modal-title){
        font-size: 16px !important;
    }
    :global(.modal-header){
        border-bottom: none;
        padding-bottom: 0px;
    }
    @media all and (min-width: $tablet-width) {
        .verticalAlignmentHelper {
            display:table;
            height: 100%;
            width: 100%;
        }
        .verticalAlignCenter {
            /* To center vertically */
            display: table-cell;
            vertical-align: middle;
        }
        .modalCenterContent {
            /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
            width:inherit;
            height:inherit;
            /* To center horizontally */
            margin: 0 auto;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
            border: 0;
        }
    }

    @media all and (max-width: $tablet-width) {
        width: 100%;
        position: fixed;
        z-index: 1900;
        top: 0;
        height: 100%;
        border: none;
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: white;

        .modalCenterContent{
            top: 0px;
            box-shadow: none;  
            border: 0;
          
        }        
    }
}