@import "../style/sizes";
@import "../style/colors";

:global(.report-picker-band){
  margin-top: 10px;
  
  padding-top: 15px;
  background-color: white;
  margin-bottom: 0px;
}
:global(.report-body-loading-band){
  background-color: white;
}
:global(.report-header-band){
  margin-bottom: -15px;
}
:global(.report-body-band){
  background-color: white;
}

header:global(.c-page-header) {
  &.headerColumn {

    @media all and (max-width: $tablet-width) {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
    }
  }
}

.exportRow:global(.row) {


  .exportColumn {
    float: right;

    :global(.btn-group) {
      float: right;
    }

    margin-bottom: 15px;

    @media all and (min-width: $mobile-width) {
      margin-top: -57px;
    }

    @media all and (max-width: $mobile-width) {
      :global(.btn-group) {
        padding-left: 10px;
        padding-right: 10px;
      }

      button {
        display: block;
        width: 100%;
      }
    }
  }
}

@media (min-width: 1170px) {
  :global {
    .flex-row.row {
      display: flex;
      flex-wrap: wrap;

      .panel {
        width: 100%;
        height: 100%;
      }
    }

    .flex-row.row>[class*="col-"] {
      display: flex;
      flex-direction: column;
    }

    .flex-row.row:after,
    .flex-row.row:before {
      display: flex;
    }
  }
}

.downloadButton {
  height: $default-button-height;
  float: right;
}