@import "../style/colors";

:global(.app .form-control.input-lg) { 
    font-size: 3.2rem;
}

:global(.login-header){
    color: $dark-text-color;
    text-align: center;
    padding-top: 50px;
}

:global(.showhidepassword) {
    span {
        font-size: 2.7rem;
    }
}

:global(#ro3ot){
    height: inherit;
}

:global(.padding-fix) {
    padding: 15px !important;
}

:global(.mainlogincontainer.container-fluid) {
    display:table;
    width: 100%;
    margin-top: -50px;
    padding: 0px 0 0 0; /*set left/right padding according to needs*/
    box-sizing: border-box;
    background-color: white !important;
}

:global(.mainlogincontainer>.row) {
    height: 100%;
    display: table-row;
}

:global(.mainlogincontainer .bigbanner) {
    background-position: center;
    background-size: cover;
}

:global(.mainlogincontainer .no-float) {
  display: table-cell;
  float: none;
}
