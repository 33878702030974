@import "../../style/sizes";

.exportButton {
    border-radius: 3px !important;
    @media all and (max-width: $mobile-width) {
        display: block;
        width: 100%;
     
    }
}

.exportButtonGroup {
    @media all and (max-width: $mobile-width) {
        display: block;
        width: 100%;
    }

    :global(.dropdown-menu) {
        @media all and (max-width: $mobile-width) {
            width: 100%;
            text-align: center;
        }

        a {
            @media all and (max-width: $mobile-width) {
                padding: 7px;
            }
        }
    }
}