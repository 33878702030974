@import "../style/sizes";

.forgottenPasswordPanel {
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
    h2, div{
        text-align: center;
        margin-bottom: 20px;
    }
    div{
        margin-top: 20px;
    }
}
