@import "../style/fonts";
@import "../style/sizes";
@import "../style/colors";

$spiner-thickness: 3px;
$spinner-size: 20px;
$spinner-background: transparent;
:global(.toast-loader-spinner) {
    border: $spiner-thickness solid $spinner-background;
    border-top: $spiner-thickness solid $primary-color;
    border-right: $spiner-thickness solid $primary-color;
    border-radius: 50%;
    width: $spinner-size;
    height: $spinner-size;
    animation: spin 1s linear infinite;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0
}

:global(.toast-loader-container) {
    float: left;    
    height: $spinner-size;
    position: relative;
    margin-right: 30px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

:global(.vp-toaster .toaster .panel-heading){
    border-color: #dadada;
    font-size: 2.4rem !important;
    padding: 6px 12px;
}

:global(#vp-toaster-wrapper){
    min-width: 250px;
    bottom: 50px;
    right: 0px;
    cursor: pointer;
    border: none;
    position: fixed;
    z-index: 2000;

    @media (min-width: $mobile-width) {
        max-width: 300px;
    }
}

:global(.vp-toaster-wrapper-top){    
    @media (max-width: $mobile-width) {
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: unset;
    }
}
:global(.vp-toaster-wrapper-bottom){    
    @media (max-width: $mobile-width) {
        top: unset;
        right: 0px;
        left: 0px;
        bottom: 0px !important;
        :global(.panel){
            margin-bottom: 0px;
        }
    }
   
}

:global(.vp-toaster) {    
    max-height: 100px;
    right: -100px;
    opacity: 0;
    overflow: hidden;

    
}

:global(.vp-toaster.fadeIn){
    transition: all 0.5s ease-in-out;    
    right: 0px;
    opacity: 1;

    @media (max-width: $mobile-width) {        
        top: 0px;
        left: 0px;
    }
}

:global(.vp-toaster.fadeOut){
    transition: all 0.7s ease-in-out;    
    opacity: 0;    
    max-height: 0;    
    
    @media (max-width: $mobile-width) {
        top: 0px;
        top: -100px;
        left: 0px;
    }
}