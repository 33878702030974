@import "../../style/colors";
@import "../../style/sizes";
@import "../../style/table";

table:global(.table) {
    thead {
        &.summary-header {
            background-color: $white-color;
            border-bottom: none;

            tr {
                th:first-child {
                    text-align: left;
                    padding-left: $table-outer-padding;

                    @media all and (max-width: $tablet-width) {
                        padding-left: $mobile-table-padding;
                    }
                }

                th:last-child {
                    padding-right: $table-outer-padding;

                    @media all and (max-width: $tablet-width) {
                        padding-right: $mobile-table-padding;
                    }
                }
            }

            tr:first-child {
                th {
                    padding-bottom: 20px;

                    @media all and (max-width: $mobile-width) {
                        padding-bottom: $mobile-table-padding;
                    }
                }
            }

            tr:last-child {
                th {
                    padding-top: 20px;

                    @media all and (max-width: $mobile-width) {
                        padding-top: $mobile-table-padding;
                    }
                }
            }

            tr {
                th {
                    border: none;
                    font-size: $bread-text-font-size;
                    color: $dark-text-color;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    text-transform: none;
                    text-align: right;
                    padding-right: $table-padding;
                    padding-top: $table-outer-padding;
                    padding-bottom: calc(#{$table-outer-padding} - 5px);

                    @media all and (max-width: $mobile-width) {
                        padding: $mobile-table-padding;
                    }
                    @media all and (max-width: $small-desktop-width) {
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                }

                th:last-child {
                    padding-left: 0;
                }
            }
        }
    }
}