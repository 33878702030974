@import "../../style/colors";
.arrow {
  &.green {
    color: $success-color;
    margin-right: 5px;
  }
  &.red {
    color: $danger-color;
    margin-right: 5px;
  }
}