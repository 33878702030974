@import "../style/colors";
@import "../style/sizes";
$toggle-indicator-color: $light-text-color;

:global(.kpi-items-box):nth-child(1){
        padding-left: 10px;
}
.KPI-cards {
    .carousel-top-bar {
        background-color: white;
        display: block;
        width: 100%;
        height: 30px;
    }
    :global(.carousel-control) {
        &:global(.left) {
            background-image: none;
            background-repeat: no-repeat;
            span {
                text-shadow: none;
                @media (max-width: $mobile-width) {
                    top: 75%;
                }
                color: $toggle-indicator-color;
            }
        }
        &:global(.right) {
            background-image: none;
            background-repeat: no-repeat;
            span {
                text-shadow: none;
                @media (max-width: $mobile-width) {
                    top: 75%;
                }
                color: $toggle-indicator-color;
            }
        }
    }
    :global(.carousel-indicators) {
        li {
            background-color: #e2e2e2;
            &:global(.active) {
                background-color: $light-text-color;
            }
        }
    }
    .carousel-item {
        margin-bottom: 15px;
        padding-top: 10px;
        padding-bottom: 20px;
        background-color: white;
    }
}