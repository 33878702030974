@import "../../style/colors";
@import "../../style/sizes";

.SwipeContainer{

        position: fixed;
        top: 0px;
        bottom: 0px;
        right: 0px;
        left: 0px;
        padding: 20px;
        overflow-y: hidden;
}
.dropdown {
    :global(.dot-tab){
        height: 10px;
        width: 10px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;  
        margin-right: 2px;      
    }
    :global(.dot-tab-active){       
        background-color: #033a6a;       
    }
    :global(.btn-link) {
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;
        white-space: pre-line;
        text-align: left;
        text-overflow: ellipsis;

        @media all and (max-width: $tablet-width) {
            max-width: 150px;
        }

        @media all and (max-width: $mobile-width) {
            max-width: 100px;
        }

    }
}

.dropdownMenu {
    position: relative;
    table {
        &.table {
            margin-bottom: 0;

            tbody {
                tr:nth-child(odd) {
                    td {
                        background-color: #fff;
                        padding-left: 0;
                    }
                }

                tr {
                    td {
                        padding: 5px;
                    }

                    td:first-child {
                        padding-left: 0;
                    }
                }
            }
        }
    }
}